import React from 'react';
import { Layout } from './components/core';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, ScrollToTop } from './pages/Routes';
import './assets/tachyons.css';
import './assets/grid.css';

function App() {
    return (
        <Router>
            <ScrollToTop />
            <Layout width={[1, 1 / 2, 1 / 4]}>
                <Routes />
            </Layout>
        </Router>
    );
}

export default App;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '../core';
// import { LanguageButton } from '../navbar/LanguageButton';
import { MenuItem } from './MenuItem';
import gumpLogo from '../../assets/images/src/gump_logo.svg';
import { BurgerMenu } from '../../assets/icons';
import cx from 'classnames';
import styles from './MobileNavbar.module.css';

export const MobileNavbar = () => {
    const [menuStatus, setMenuStatus] = useState(false);
    const [menuItemsStatus, setMenuItemsStatus] = useState(false);
    const { t } = useTranslation();

    const navbarStyle = cx(
        'flex flex-column bg-white w-100 fixed z-5 ph24 pt20 pb24 shadow-0 overflow-hidden',
        {
            [styles.menuHeight]: !menuStatus,
            [styles.menuExpanded]: menuStatus && !menuItemsStatus,
            [styles.menuAndSubmenuExpanded]: menuStatus && menuItemsStatus,
        }
    );

    const scrollToContact = () => {
        const contact = document && document.getElementById('contact');
        contact.scrollIntoView(false);
        setMenuItemsStatus(false);
        return setMenuStatus(false);
    };

    const scrollToAboutUs = () => {
        const differentials = document && document.getElementById('about');
        differentials.scrollIntoView(false);
        setMenuItemsStatus(false);
        return setMenuStatus(false);
    };

    return (
        <div className={navbarStyle}>
            <div className="flex justify-between pv0 ph0 gump-blue">
                <Link to="/">
                    <img
                        src={gumpLogo}
                        className="pt4"
                        style={{ height: '24px' }}
                        alt="Gump"
                    />
                </Link>
                <div className="flex items-start">
                    {/* <LanguageButton
                        i18nHandler={language => i18n.changeLanguage(language)}
                    /> */}
                    <button
                        className="bg-transparent pa0 pv10 bn pointer gump-blue"
                        onClick={() => setMenuStatus(!menuStatus)}>
                        <BurgerMenu />
                    </button>
                </div>
            </div>
            <div className="mt4 pt4 bb b--gump-light-gray" />
            <div className="fontSource">
                <MenuItem label="navbar.aboutUs" onClick={scrollToAboutUs} />
                <MenuItem label="navbar.contact" onClick={scrollToContact} />

                <div className="w-100">
                    <Button
                        className="w-100"
                        onClick={() =>
                            (window.location.href = 'https://app.gump.ai/')
                        }
                        outlined>
                        {t('navbar.login')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import { FormPage } from './FormPage';
import { Main } from './Main';
import { NotFound } from './NotFound';

export const Routes = () => (
    <Switch>
        <Route exact path="/" component={Main} />
        <Route exact path="/nao-encontrado" component={NotFound} />
        <Route path="/:form" component={FormPage} />
    </Switch>
);

export function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

import React from 'react';

const GumpLogo_White = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Group_4845"
        width="207.068"
        height="40"
        viewBox="0 0 207.068 40">
        <defs>
            <style>{'.cls-1{fill:#ffffff}'}</style>
        </defs>
        <g id="Group_4809">
            <path
                id="Path_940"
                d="M1566.011-788.256a2.165 2.165 0 0 1 2.162 2.162v7.769a3.6 3.6 0 0 0 1.056 2.548l3.917 3.917-3.917 3.917a3.6 3.6 0 0 0-1.056 2.548v7.769a2.164 2.164 0 0 1-2.162 2.162h-28.468a2.165 2.165 0 0 1-2.162-2.162v-28.469a2.165 2.165 0 0 1 2.162-2.162h28.468m0-3.6h-28.468a5.766 5.766 0 0 0-5.766 5.766v28.469a5.766 5.766 0 0 0 5.766 5.766h28.468a5.766 5.766 0 0 0 5.766-5.766v-7.769l4.427-4.427a2.883 2.883 0 0 0 0-4.077l-4.427-4.427v-7.769a5.766 5.766 0 0 0-5.766-5.766z"
                className="cls-1"
                transform="translate(-1531.777 791.859)"
            />
            <g id="Group_4808" transform="translate(9.333 11.337)">
                <g id="Group_4806" transform="translate(4.015)">
                    <rect
                        id="Rectangle_2385"
                        width="20.129"
                        height="4.384"                
                        className="cls-1"
                        opacity="0.8"
                        rx="2.192"
                        transform="rotate(-45 17.18 7.117)"
                    />
                    <circle
                        id="Ellipse_266"
                        cx="2.192"
                        cy="2.192"
                        r="2.192"
                        className="cls-1"
                        transform="translate(12.041 .908)"
                    />
                </g>
                <g
                    id="Group_4807"
                    fill="#004de8"
                    transform="translate(0 7.097)">
                    <rect
                        id="Rectangle_2386"
                        width="4.381"
                        height="10.061"
                        opacity="0.24"
                        className="cls-1"
                        rx="2.191"
                        transform="rotate(-45 3.74 1.549)"
                    />
                    <circle
                        id="Ellipse_267"
                        cx="2.192"
                        cy="2.192"
                        r="2.192"
                        className="cls-1"
                        opacity="0.2"
                        transform="translate(.907 .907)"
                    />
                </g>
            </g>
        </g>
        <g id="Group_4812" transform="translate(54.039 12.569)">
            <g id="Group_4810">
                <path
                    id="Path_941"
                    d="M1721.419-729.282a3.789 3.789 0 0 0 1.364-2.981v-1.91a4.7 4.7 0 0 1-1.948 1.636 6.172 6.172 0 0 1-2.728.624 8.186 8.186 0 0 1-6.292-2.63 8.939 8.939 0 0 1-2.435-6.293 9.664 9.664 0 0 1 2.552-6.838 8.38 8.38 0 0 1 6.409-2.747 5.47 5.47 0 0 1 4.715 2.416l.078-.74a1.609 1.609 0 0 1 .8-1.091 3.052 3.052 0 0 1 1.656-.429 2.6 2.6 0 0 1 1.832.643 2.189 2.189 0 0 1 .7 1.695q-.078 8.495-.078 11.767l.039 4.013a8.334 8.334 0 0 1-2.708 6.468 9.747 9.747 0 0 1-6.994 2.65 11.854 11.854 0 0 1-5.572-1.091 2.647 2.647 0 0 1-1.636-2.416 2.763 2.763 0 0 1 .682-1.851 2.065 2.065 0 0 1 1.617-.8 8.2 8.2 0 0 1 1.792.507 8.77 8.77 0 0 0 3 .584 4.66 4.66 0 0 0 3.155-1.186zm-5.494-8.553a3.854 3.854 0 0 0 2.884 1.208 4.046 4.046 0 0 0 2.922-1.169 4.5 4.5 0 0 0 1.208-3.39 4.66 4.66 0 0 0-1.169-3.409 3.8 3.8 0 0 0-2.805-1.189 3.764 3.764 0 0 0-3.02 1.286 5.055 5.055 0 0 0-1.188 3.507 4.346 4.346 0 0 0 1.169 3.156z"
                    className="cls-1"
                    transform="translate(-1709.38 750.46)"
                />
                <path
                    id="Path_942"
                    d="M1791.169-747.053v6.39q0 4.559 2.922 4.559 3.78 0 3.779-5.805l.039-5.338a2.3 2.3 0 0 1 .779-1.909 3.083 3.083 0 0 1 1.987-.623q2.61 0 2.611 2.455l-.02 1.013q-.02.974-.02 2.1v2.727q0 1.6.039 2.883.038 1.52.1 3.156t.059 1.831a1.716 1.716 0 0 1-.76 1.5 3.169 3.169 0 0 1-1.85.526q-1.987 0-2.338-1.6c0-.078-.019-.292-.058-.643s-.084-.669-.136-.955a5.871 5.871 0 0 1-5.377 3.351 6.335 6.335 0 0 1-5.338-2.26 9.73 9.73 0 0 1-1.753-6.118q0-1.091.02-3.7t.019-3.663q0-2.61 2.961-2.61 2.335.006 2.335 2.733z"
                    className="cls-1"
                    transform="translate(-1762.569 750.015)"
                />
                <path
                    id="Path_943"
                    d="M1859.307-745.193q-.019-1.305-.039-1.987t-.02-.955q0-2.377 2.767-2.377a2.039 2.039 0 0 1 2.26 1.753l.117 1.013a4.739 4.739 0 0 1 2.046-2.026 5.972 5.972 0 0 1 2.9-.78 5.85 5.85 0 0 1 3.215.857 5.346 5.346 0 0 1 2.007 2.26 6.824 6.824 0 0 1 2.377-2.2 6.555 6.555 0 0 1 3.156-.916 6.223 6.223 0 0 1 5.163 2.007 8.375 8.375 0 0 1 1.7 5.514l.039 4.169q0 2.1-.117 4.715-.078 1.987-2.65 1.987a3.042 3.042 0 0 1-1.928-.565 1.851 1.851 0 0 1-.721-1.539q0-.935.059-3.6t.058-4.383q0-3.74-2.572-3.74a3.029 3.029 0 0 0-2.26.916 3.467 3.467 0 0 0-.935 2.436q-.039 1.558-.039 4.325 0 2.532-.039 3.857a2.242 2.242 0 0 1-.76 1.734 2.826 2.826 0 0 1-1.968.565q-2.532-.078-2.532-2.143.077-7.13.078-8.183a4.693 4.693 0 0 0-.74-2.533 2.252 2.252 0 0 0-1.987-1.052q-3.429 0-3.351 4.909v6.9a1.763 1.763 0 0 1-.8 1.578 3.5 3.5 0 0 1-1.968.526 3.091 3.091 0 0 1-1.851-.545 2.011 2.011 0 0 1-.76-1.754q0-.779.039-2.3l.078-5.221q-.002-1.917-.022-3.222z"
                    className="cls-1"
                    transform="translate(-1813.621 750.551)"
                />
                <path
                    id="Path_944"
                    d="M1967.842-750.51a2.048 2.048 0 0 1 2.221 1.6l.117 1.286a6.044 6.044 0 0 1 2.318-2.143 6.722 6.722 0 0 1 3.215-.78 7.73 7.73 0 0 1 6.117 2.572 9.552 9.552 0 0 1 2.26 6.546 9.416 9.416 0 0 1-2.571 6.78 8.714 8.714 0 0 1-6.585 2.688 5.445 5.445 0 0 1-4.559-1.987q0 .272.02 1.344t.02 1.617q0 .429.039 2.1t.039 2.728q0 2.571-3 2.571a2.136 2.136 0 0 1-2.376-2.455l.078-15.2-.039-6.936q.037-2.331 2.686-2.331zm9.741 5.805a3.833 3.833 0 0 0-2.844-1.247 4.138 4.138 0 0 0-3.078 1.325 4.666 4.666 0 0 0-1.286 3.39 5.064 5.064 0 0 0 1.072 3.468 3.876 3.876 0 0 0 3.059 1.208 3.922 3.922 0 0 0 3.059-1.383 5.172 5.172 0 0 0 1.227-3.565 4.4 4.4 0 0 0-1.209-3.191z"
                    className="cls-1"
                    transform="translate(-1887.303 750.55)"
                />
            </g>
            <g id="Group_4811" transform="translate(104.073 .4)">
                <path
                    id="Path_945"
                    d="M2052.924-743.109h-1.5v-1.126h1.5v-.775c0-3.227 1.8-4.228 3.7-4.228a5.531 5.531 0 0 1 .675.05v1.125a4.8 4.8 0 0 0-.625-.05c-1.6 0-2.5.926-2.5 3.077v.8h3.027v1.126h-3.027v11.857h-1.251z"
                    className="cls-1"
                    transform="translate(-2051.423 749.238)"
                />
                <path
                    id="Path_946"
                    d="M2080.959-733.863a6.578 6.578 0 0 1 6.654 6.8 6.6 6.6 0 0 1-6.679 6.829 6.541 6.541 0 0 1-6.654-6.829 6.569 6.569 0 0 1 6.679-6.8zm-.025 12.457a5.333 5.333 0 0 0 5.378-5.653 5.339 5.339 0 0 0-5.378-5.629 5.334 5.334 0 0 0-5.353 5.629 5.313 5.313 0 0 0 5.353 5.653z"
                    className="cls-1"
                    transform="translate(-2067.325 738.541)"
                />
                <path
                    id="Path_947"
                    d="M2132.564-732.637c-3.077 0-4 2.527-4 5.228v6.854h-1.25v-12.983h1.25v2.326a4.138 4.138 0 0 1 4-2.651z"
                    className="cls-1"
                    transform="translate(-2104.221 738.541)"
                />
                <path
                    id="Path_948"
                    d="M2171.253-720.555H2170v-7.83c0-2.877-1.126-4.328-3.377-4.328-2.1 0-3.727 1.5-3.727 4.353v7.8h-1.25v-8.33c0-2.4-1.351-3.827-3.427-3.827-1.976 0-3.677 1.7-3.677 4.252v7.9h-1.251v-12.983h1.251v2.051a4.22 4.22 0 0 1 3.827-2.376 4.1 4.1 0 0 1 4.1 2.6 4.454 4.454 0 0 1 4.3-2.6c2.877 0 4.478 2.076 4.478 4.953z"
                    className="cls-1"
                    transform="translate(-2122.296 738.541)"
                />
            </g>
        </g>
    </svg>
);

export default GumpLogo_White;

import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export const SectionTitle = ({ title, subtitle }) => {
    const { t } = useTranslation();
    return (
        <div className="Grid">
            <div className="u-size1of12" />
            <div className="u-size11of12">
                <h2 className="f32 fw9 gump-blue tracked-tight ma0 mt32 lh-solid mb10 mr24 mr0-ns">
                    {t(title)}
                </h2>
                <h3 className="f16 fw4 gump-dark-gray mt10-ns mb12 mb32-ns mr24 mr0-ns">
                    {t(subtitle)}
                </h3>
            </div>
        </div>
    );
};

SectionTitle.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

import React from 'react';

const Gratuity = props => (
    <svg width="60" height="48" {...props}>
        <g>
            <path
                fill="#0096fe"
                d="M0 0h44a4 4 0 0 1 4 4v40a4 4 0 0 1-4 4H0V0z"
                transform="translate(-161 -895) translate(173 895)"
            />
            <path
                fill="#0000fe"
                d="M0 0h18a12 12 0 0 1 12 12 12 12 0 0 1-12 12H0V0z"
                transform="translate(-161 -895) translate(173 907)"
            />
            <text
                fill="#fff"
                fontFamily="SourceSansPro-SemiBold, Source Sans Pro"
                fontSize="16px"
                fontWeight="600"
                transform="translate(-161 -895) translate(183 924)">
                <tspan x="0" y="0">
                    $
                </tspan>
            </text>
            <path
                fill="#00e8fe"
                d="M0 0H12V24H0z"
                transform="translate(-161 -895) translate(161 907)"
            />
        </g>
    </svg>
);
export default Gratuity;

import React from 'react';

const ToyGames = props => (
    <svg width="181.181" height="40" {...props}>
        <defs>
            <style>
                {
                    '.cls-1,.cls-2,.cls-3{fill:#adbcd8}.cls-1,.cls-2{stroke:transparent}.cls-2{stroke-miterlimit:10}'
                }
            </style>
        </defs>
        <g id="Group_4886" transform="translate(-584.074 -2691)">
            <g id="Group_4888" transform="translate(628.061 2703.049)">
                <path
                    id="Path_1249"
                    d="M1224.9 521.785c-.775 2.494-2.638 3.288-5.081 3.26-2.908-.033-5.818-.044-8.726.013-.961.019-1.29-.223-1.276-1.227.032-2.43-.023-2.431 2.45-2.431 2.407 0 4.814-.013 7.222.006.928.007 1.546-.187 1.519-1.314-.026-1.075-.671-1.184-1.5-1.184-1.855 0-3.714.047-5.565-.046-2.92-.148-4.152-1.725-4.1-5.077.046-2.913 1.437-4.477 4.223-4.527 3.61-.064 7.221-.011 10.832-.008v3.581a2.581 2.581 0 0 1-1.851.432c-2.2.012-4.4 0-6.6.006a6.433 6.433 0 0 0-1.346.078c-.355.076-.694.2-.717.63-.028.52.348.671.765.739a14.087 14.087 0 0 0 2.1.065c1.1.017 2.2-.053 3.3.051 2.622.248 3.746 1.2 4.355 3.669z"
                    className="cls-2"
                    transform="translate(-1088.204 -509.197)"
                />
                <path
                    id="Path_1251"
                    d="M1103.458 518.717c0 1.689-.041 3.38.017 5.067.03.871-.174 1.209-1.148 1.212-2.779.008-2.779.058-2.777-2.7 0-2.584-.081-5.171.035-7.749.069-1.542-.6-1.833-1.959-1.8-1.261.031-2.088.092-2.021 1.734.125 3.026-.011 6.061.061 9.09.026 1.075-.2 1.467-1.383 1.431-2.549-.077-2.551-.006-2.551-2.48v-7.9c0-1.839-.017-1.774-1.992-1.867-1.384-.065-2 .282-1.947 1.8.106 3.027-.009 6.061.054 9.09.021 1.01-.217 1.37-1.308 1.349-2.622-.05-2.623.008-2.623-2.562 0-3.974.048-7.95-.03-11.923-.023-1.152.331-1.465 1.433-1.367 2.729.244 5.46-.073 8.227.33 1.52.222 3.318-.325 5.008-.312 3.475.027 4.883 1.447 4.9 4.932.012 1.545.004 3.085.004 4.625z"
                    className="cls-2"
                    transform="translate(-1000.207 -509.128)"
                />
                <path
                    id="Path_1252"
                    d="M1163.367 509.2c1.6 0 3.211.034 4.815-.014.807-.024 1.134.17 1.151 1.057.051 2.577.1 2.576-2.462 2.576-2.408 0-4.816.041-7.222-.018-1.134-.028-1.481.449-1.513 1.5-.036 1.192.771.922 1.419.925 2.809.013 5.619.05 8.426-.017 1.056-.025 1.474.3 1.337 1.34a4.743 4.743 0 0 0 0 1.191c.118.95-.333 1.17-1.215 1.156-2.758-.044-5.517-.038-8.275 0-.69.009-1.686-.436-1.692.976-.005 1.341.709 1.531 1.822 1.512 2.657-.045 5.319.046 7.974-.042 1.166-.039 1.443.354 1.4 1.439-.1 2.66.249 2.194-2.231 2.22-2.608.027-5.216.021-7.824 0-3.5-.026-4.947-1.4-5.007-4.874-.041-2.383-.057-4.776.112-7.15.172-2.415 1.58-3.654 4.021-3.751 1.653-.066 3.31-.013 4.965-.013z"
                    className="cls-2"
                    transform="translate(-1049.371 -509.164)"
                />
                <path
                    id="Path_1253"
                    d="M982.021 509.176c1.6 0 3.211.055 4.812-.021.94-.044 1.177.258 1.172 1.17-.014 2.469.033 2.469-2.395 2.469-2.457 0-4.914.03-7.37-.014-1.033-.019-1.447.357-1.42 1.393.049 1.937.034 3.876 0 5.813-.013.831.229 1.356 1.159 1.356 1.655 0 3.309.009 4.964-.006.6-.005 1.146-.2 1.09-.926s-.682-.368-1.064-.43a3.239 3.239 0 0 0-1.05 0c-1.451.254-1.8-.312-1.823-1.749-.021-1.571.43-2.042 1.982-1.91a45.8 45.8 0 0 0 4.961.019c.7-.017.986.189.951.92-.052 1.091.007 2.186-.021 3.279-.07 2.769-1.44 4.27-4.221 4.408-2.2.11-4.415.11-6.615 0-2.785-.14-4.158-1.635-4.2-4.417-.036-2.335-.041-4.671 0-7.006.053-2.826 1.535-4.266 4.42-4.331 1.553-.035 3.108-.006 4.662-.006z"
                    className="cls-2"
                    transform="translate(-922.66 -509.144)"
                />
                <path
                    id="Exclusion_1"
                    d="M7.457 15.872c-1.158 0-2.3-.032-3.4-.1C1.434 15.624.08 14.191.033 11.518c-.045-2.531-.044-4.99 0-7.309a4.233 4.233 0 0 1 1.128-3.058A4.462 4.462 0 0 1 4.288.038C5.346.013 6.426 0 7.5 0s2.171.013 3.26.038a4.436 4.436 0 0 1 3.1 1.112 4.433 4.433 0 0 1 1.2 3.064c.044.853.032 1.724.021 2.565-.005.382-.01.776-.01 1.164h.006c0 .358 0 .722.006 1.075.006.773.012 1.571-.011 2.356a4.691 4.691 0 0 1-1.111 3.192 4.451 4.451 0 0 1-3.129 1.221c-1.15.057-2.285.085-3.375.085zM5.13 3.622a1.24 1.24 0 0 0-.929.3 1.4 1.4 0 0 0-.287 1.059c.023.852.018 1.719.013 2.558 0 .579-.007 1.178 0 1.767 0 .389 0 .724-.007 1.018-.014.847-.022 1.314.229 1.57.275.279.846.309 2.085.309h.631c4.306-.011 4.306-.011 4.305-4.366v-.854c-.005-.65-.011-1.32.011-1.979a1.419 1.419 0 0 0-.29-1.069 1.249 1.249 0 0 0-.941-.307h-.071c-.816.019-1.615.029-2.373.029-.783 0-1.554-.01-2.292-.03z"
                    className="cls-2"
                    transform="translate(16.793 .015)"
                />
                <path
                    id="Exclusion_2"
                    d="M14.541 15.567a1.391 1.391 0 0 1-.431-.084c-.214-.071-.4-.126-.577-.166l-.055-.013c-2.34-.547-2.431-.568-2.3-2.878a1.808 1.808 0 0 0-.3-1.372 1.333 1.333 0 0 0-1.024-.324c-.131 0-.273.007-.434.022-.431.039-.907.059-1.454.059-.469 0-.946-.014-1.407-.027l-.6-.016c-.107 0-.217-.007-.323-.011-.162-.007-.328-.013-.485-.013a1.421 1.421 0 0 0-1.122.345 1.55 1.55 0 0 0-.153 1.285c.025.133-.053.384-.124.4a2.248 2.248 0 0 1-.53.061 4.843 4.843 0 0 1-1.4-.282 6.107 6.107 0 0 0-1.117-.267C.078 12.218 0 11.794 0 11.345c.008-.8.007-1.623.007-2.415 0-1.749 0-3.557.09-5.33a3.648 3.648 0 0 1 1.046-2.481A3.876 3.876 0 0 1 3.7.1C4.933.032 6.207 0 7.486 0 8.7 0 9.958.029 11.217.086A4.673 4.673 0 0 1 12.7.381a3.362 3.362 0 0 1 1.13.714 3.339 3.339 0 0 1 .748 1.1 4.6 4.6 0 0 1 .336 1.465c.1 1.37.075 2.772.052 4.128-.01.6-.021 1.217-.021 1.824h.115v1.445c0 1.187-.007 2.415.012 3.622a1.05 1.05 0 0 1-.182.758.455.455 0 0 1-.349.13zM8.9 7.191c.354 0 .645.012.916.038.117.011.226.017.326.017a1.069 1.069 0 0 0 .853-.287 1.241 1.241 0 0 0 .165-1.015 4.17 4.17 0 0 1-.017-.726 1.712 1.712 0 0 0-.261-1.265 1.391 1.391 0 0 0-1.036-.316h-.093c-.561.014-1.156.02-1.875.02-.534 0-1.077 0-1.6-.007l-1.162-.007H5.1c-.485 0-.938.051-1.161.665a2.37 2.37 0 0 0 0 2.178 2.3 2.3 0 0 0 1.991.728h1.5c.245 0 .5-.006.737-.011s.486-.012.733-.012z"
                    className="cls-2"
                    transform="translate(67.017 .005)"
                />
                <path
                    id="Path_1256"
                    d="M922.841 522.712v-.745c0-2.172 0-2.172-2.2-2.493a3.6 3.6 0 0 1-3.229-3.056 32.609 32.609 0 0 1-.192-5.645c0-.187.056-.524.141-.542 1.07-.229 2.018.34 3.034.5.56.087.736.392.725.918-.02.993.008 1.986 0 2.979-.012.925.382 1.348 1.361 1.309a33.232 33.232 0 0 1 4.055.011c1.458.121 2.023-.4 1.852-1.84-.095-.8-.146-1.806 1.177-1.192 1 .464 2.82-.284 2.738 1.893-.1 2.673-1.038 4.2-3.092 4.639-.776.167-1.907-.262-2.264.366-.392.689-.16 1.728-.192 2.616-.012.347 0 .695 0 1.043 0 2.244 0 2.176-2.338 2.263-1.218.046-1.782-.25-1.584-1.533a9.838 9.838 0 0 0 .008-1.491z"
                    className="cls-3"
                    transform="translate(-883.73 -509.862)"
                />
                <path
                    id="Path_1257"
                    d="M813.768 509.186c2.056 0 4.113.048 6.166-.021.987-.033 1.4.278 1.246 1.254a2.184 2.184 0 0 0 0 .3c-.037 2.438.283 2.063-2.037 2.079-3.474.023-3.474.007-3.474 3.505 0 2.435-.028 4.87.014 7.3.016.915-.156 1.225-1.189.973-2.72-.663-2.733-.611-2.733-3.407 0-2.385-.046-4.771.022-7.154.029-1.006-.323-1.3-1.291-1.232-1.048.074-2.107-.027-3.157.03-.866.048-1.261-.162-1.233-1.134.09-3.091-.294-2.431 2.4-2.488 1.755-.035 3.511-.005 5.266-.005z"
                    className="cls-3"
                    transform="translate(-806.099 -509.153)"
                />
            </g>
            <g id="Group_4887" transform="translate(584.074 2691)">
                <path
                    id="Path_1246"
                    d="M688.545 538.413c.914 1.308 1.888 1.254 3.148.356 2.277-1.622 4.661-3.1 6.988-4.649a2.419 2.419 0 0 0-.333-4.024c-.43-.212-.654.143-.919.311-1.057.666-2.121 1.328-3.121 2.072-.731.545-.983.236-1.262-.434a6.59 6.59 0 0 1 2.43-8.045c1.34-.958 2.759-1.806 4.113-2.747.562-.39.871-.4 1.279.235 1.537 2.384 3.219 4.68 4.7 7.1 2.326 3.793 1.735 7.2-1.861 9.807-3.311 2.4-6.789 4.581-10.241 6.787a18.68 18.68 0 0 1-2.865 1.235h-2.71a7.2 7.2 0 0 1-4.837-3.421q-3.6-5.119-6.988-10.376c-2.172-3.36-2.122-6.456 0-9.1a2.986 2.986 0 0 1 1.492-1.185c.662-.093 1.006.349 1.32.8 3.239 4.711 6.157 9.631 9.434 14.319a.933.933 0 0 1 .233.959z"
                    className="cls-3"
                    transform="translate(-670.727 -506.412)"
                />
                <path
                    id="Path_1247"
                    d="M678.495 506.713q-4.846-7.333-9.7-14.662c-.319-.481-.519-1.1-1.18-1.279-.676-.9-1.4-.883-2.272-.22-.909.689-1.887 1.292-2.856 1.9-1.431.9-2 .621-2.254-1.07-.043-.283.08-.6-.161-.842v-2.388a9.724 9.724 0 0 1 4.424-5.221c3.136-1.954 6.163-4.079 9.267-6.084a6.733 6.733 0 0 1 8.564.656c.733.682.651 1-.16 1.516-2.737 1.746-5.39 3.621-8.152 5.326-1.179.728-1.436 1.228-.55 2.464 2.407 3.356 4.67 6.814 6.939 10.264a6.809 6.809 0 0 1-.9 9.094c-.285.273-.548.588-1.009.546z"
                    className="cls-3"
                    transform="translate(-660.074 -475.616)"
                />
                <path
                    id="Path_1258"
                    d="M720.564 493.507a6.765 6.765 0 0 1-2.678 5.128c-3.566 2.925-3.579 2.908-6.108-.845-1.3-1.932-1.3-1.933.679-3.242 2.086-1.378 4.183-2.74 6.253-4.141.622-.421 1.013-.525 1.283.341a7.979 7.979 0 0 1 .571 2.759z"
                    className="cls-3"
                    transform="translate(-695.571 -485.731)"
                />
            </g>
        </g>
    </svg>
);
export default ToyGames;

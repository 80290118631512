import React, { useState } from 'react';
import EmailValidator from 'email-validator';
import { ArrowLeft } from '../../assets/icons';
import { Button } from '../core';
import styles from './Newsletter.module.css';
import axios from 'axios';

export const Newsletter = () => {
    const [value, setValue] = useState('');
    const [isValid, setValid] = useState(true);
    const [successfullySent, setSuccessfullySent] = useState('');

    const handleChange = value => {
        setSuccessfullySent(false);
        setValid(true);
        setValue(value);
    };

    const onSend = () => {
        const checkMail = EmailValidator.validate(value);
        if (!checkMail) {
            return setValid(false);
        }
        axios.post('http://127.0.0.1:5002/newsletter', {
            email: document.getElementById('email').value})
            .then(function (response) {
                console.log(response.data);
            });

        setSuccessfullySent(true);
        setValue('');
    };
    const handleSend = key => {
        if (key === 'Enter') {
            return onSend();
        }
        return;
    };
    return (
        <div
            id="contato"
            className="pa32 flex flex-column justify-center items-center mc center">
            <p className={`pt12 f24 ma0 white tc ${styles.maxWidth}`}>
                Quer ficar por dentro das principais noticias e novidades
                relacionadas a nossa plataforma?
            </p>
            <p className={`f24 ma0 white tc ${styles.maxWidth}`}>
                Inscreva-se abaixo.
            </p>
            <p className="f16 mt20 mb16 white tc">
                Não se preocupe, não enviaremos spam{' '}
                <span role="img" aria-label="Emoji de polegar para cima">
                    👍
                </span>
            </p>
            <div className="relative pb12">
                <input
                    value={value}
                    onChange={e => handleChange(e.currentTarget.value)}
                    id="email"
                    className={`br-pill bn ${styles.input}`}
                    placeholder="Digite seu e-mail…"
                    onKeyDown={e => handleSend(e.key)}
                />
                {!isValid && <p className="tc white pt10">E-mail inválido.</p>}
                {successfullySent && (
                    <p className="tc white pt10">
                        Obrigado! Nossas novidades chegarão sempre fresquinhas
                        no seu e-mail!
                    </p>
                )}
                <Button
                    onClick={() => onSend()}
                    disabled={value.length === 0}
                    color="gump-green"
                    className={`static absolute-ns db w-100 w-auto-ns mt10 mt0-ns flex-ns justify-center ${styles.button}`}
                    icon={<ArrowLeft />}>
                    Assinar
                </Button>
            </div>
        </div>
    );
};

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import {
    MobileNavbar,
    Navbar,
    MainCard,
    Lead,
    SectionTitle,
    Newsletter,
    Footer,
    Feature,
    Brands,
    Carousel,
} from '../components';
import { Dots, Gratuity, Simplicity, Practicality } from '../assets/images';
import dataCollect from '../assets/images/src/coleta_dados.svg';
import dataMetric from '../assets/images/src/metricas_dados.svg';
import reports from '../assets/images/src/relatorios_dados.svg';

export const Main = () => {
    const isMobile = useMediaQuery({
        query: '(max-width: 1024px)',
    });
    return (
        <section className="sans-serif lh-subtitle">
            {isMobile && <MobileNavbar />}
            {!isMobile && <Navbar />}
            <div className="w-100 relative">
                <section id="about" className="mc center">
                    <Lead />
                    <SectionTitle
                        title="main.gumpDifferentialsTitle"
                        subtitle="main.gumpDifferentialsSubtitle"
                    />
                </section>
                <section className="mc center">
                    <div className="Grid">
                        <div className="u-size1of12" />
                        <div className="Grid u-size10of12 justify-center justify-start-ns justify-between-m justify-between-l">
                            <MainCard
                                logo={<Gratuity />}
                                title="main.gumpFreeCardTitle"
                                description="main.gumpFreeCardDescription"
                            />
                            <MainCard
                                logo={<Simplicity />}
                                title="main.gumpIntuitiveCardTitle"
                                description="main.gumpIntuitiveCardDescription"
                            />
                            <MainCard
                                logo={<Practicality />}
                                title="main.gumpEasyCardTitle"
                                description="main.gumpEasyCardDescription"
                            />
                        </div>
                        <div className="u-size1of12 u-sm-sizeFit u-md-size1of12 u-lg-size1of12" />
                    </div>
                </section>

                <section className="mc center">
                    <SectionTitle
                        title="main.resourcesTitle"
                        subtitle="main.resourcesSubtitle"
                    />
                    <section className="flex flex-column items-center">
                        <Feature
                            image={dataCollect}
                            title="main.dataCollectFeatureTitle"
                            content="main.dataCollectFeatureDescription"
                        />
                        <Dots className="dn db-ns" />
                        <Feature
                            image={dataMetric}
                            title="main.dataFeatureTitle"
                            content="main.dataFeatureDescription"
                            textFloat="right"
                            textLeft
                        />
                        <Dots
                            className="dn db-ns"
                            style={{ transform: 'scaleX(-1)' }}
                        />
                        <Feature
                            image={reports}
                            className="mb0"
                            title="main.reportsFeatureTitle"
                            content="main.reportsFeatureDescription"
                        />
                    </section>
                </section>

                <section className="bg-gump-light-gray pb72 pt32 mt32">
                    <div className="mc center">
                        <SectionTitle
                            title="main.brandsTitle"
                            subtitle="main.brandsDescription"
                        />
                        <div className="Grid">
                            <div className="u-size1of12" />
                            <div className="u-size10of12 flex justify-between">
                                <Brands />
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="mb32 mc center pb32">
                    <SectionTitle
                        title="main.testimonialsTitle"
                        subtitle="main.testimonialsDescription"
                    />
                    <div className="flex justify-center">
                        <Carousel />
                    </div>
                </section>

                <section className="bg-gump-blue">
                    <Newsletter />
                </section>
                <footer id="contact" className="bg-gump-dark-blue">
                    <Footer />
                </footer>
            </div>
        </section>
    );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Arrow } from '../../assets/icons';
import PropTypes from 'prop-types';

const MenuItem = ({ url, onClick, content, currentStatus }) => {
    const { t } = useTranslation();
    if (onClick) {
        return (
            <li className="pl4">
                <button
                    className="bn flex bg-transparent pointer b--gump-dark-blue gump-dark-blue justify-center items-start"
                    onClick={() => onClick()}>
                    {t(content)}

                    <Arrow
                        className={currentStatus ? 'rotate-270' : 'rotate-90'}
                    />
                </button>
                <div className="center br-pill w-50 ba bw1 b--gump-blue" />
            </li>
        );
    }
    return (
        <li className="ph10">
            <a className="link gump-dark-blue" href={url} title={content}>
                {t(content)}
            </a>
        </li>
    );
};

export const MenuItems = () => {
    return (
        <ul className="flex list ma0">
            <div className="flex">
                <MenuItem url="#about" content="navbar.aboutUs" />
            </div>
            <MenuItem url="#contact" content="navbar.contact" />
        </ul>
    );
};

MenuItems.propTypes = {
    statusHandler: PropTypes.func,
    currentStatus: PropTypes.bool,
};

MenuItem.propTypes = {
    url: PropTypes.string,
    onClick: PropTypes.func,
    content: PropTypes.string,
    currentStatus: PropTypes.bool,
};

import React from 'react';

function BurgerMenu(props) {
    return (
        <svg width={20} height={12} viewBox="0 0 20 12" {...props}>
            <path
                d="M1 2h18a1 1 0 000-2H1a1 1 0 000 2zm18 8H1a1 1 0 000 2h18a1 1 0 000-2zm0-5H1a1 1 0 000 2h18a1 1 0 000-2z"
                fill="#004de8"
            />
        </svg>
    );
}

export default BurgerMenu;

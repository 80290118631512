import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Quotes } from '../../assets/icons/';
import styles from './Carousel.module.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../assets/reset.css';
import daniel from './images/daniel.png';
import wellington from './images/wellington.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const CarouselItem = ({ photo, name, profession, review }) => {
    return (
        <div className="Grid items-center justify-center">
            <div className="u-sm-sizeFull u-md-size5of12 u-lg-size5of12 mr16">
                <Quotes />
                <p className="tc f16 gump-dark-blue">{review}</p>
            </div>
            <div className="u-sizeFull u-md-size5of12 u-lg-size5of12">
                <div className="flex items-center justify-center">
                    <img
                        src={photo}
                        className={`br-100 ${styles.avatar}`}
                        alt={name}
                    />
                    <div className="ml16">
                        <p className="f16 fw9 gump-dark-blue">{name}</p>
                        <p className="f12 fw4 gump-dark-gray">{profession}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Carousel = () => {
    return (
        <div className={`relative ${styles.carouselWidth}`}>
            <Slider {...settings}>
                <div
                    className={`flex justify-center item-center ${styles.itemContainer}`}>
                    <CarouselItem
                        photo={wellington}
                        name="Wellington"
                        profession="CEO - Toy Games"
                        review="Gump Form nos ajudou muito a tornar os formulários de feedback mais interessantes para nossos clientes, aumentando o engajamento."
                    />
                </div>
                <div>
                    <CarouselItem
                        photo={daniel}
                        name="Daniel"
                        profession="Co-Founder - Semantika.co"
                        review="Nossos clientes precisavam de uma ferramenta que permitisse a coleta de informações mais assertivas. Gump Form resolveu nossa dor."
                    />
                </div>
            </Slider>
        </div>
    );
};

CarouselItem.propTypes = {
    photo: PropTypes.string,
    name: PropTypes.string,
    profession: PropTypes.string,
    review: PropTypes.string,
};

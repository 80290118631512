import React from 'react';

const Instagram = props => (
    <svg width={24} height={24} {...props}>
        <g data-name="Group 4464">
            <path
                d="M12 9.52A2.48 2.48 0 1014.48 12 2.48 2.48 0 0012 9.52zm9.93-2.45a6.53 6.53 0 00-.42-2.26 4 4 0 00-2.32-2.32 6.53 6.53 0 00-2.26-.42C15.64 2 15.26 2 12 2s-3.64 0-4.93.07a6.53 6.53 0 00-2.26.42 4 4 0 00-2.32 2.32 6.53 6.53 0 00-.42 2.26C2 8.36 2 8.74 2 12s0 3.64.07 4.93a6.86 6.86 0 00.42 2.27 3.94 3.94 0 00.91 1.4 3.89 3.89 0 001.41.91 6.53 6.53 0 002.26.42C8.36 22 8.74 22 12 22s3.64 0 4.93-.07a6.53 6.53 0 002.26-.42 3.89 3.89 0 001.41-.91 3.94 3.94 0 00.91-1.4 6.6 6.6 0 00.42-2.27C22 15.64 22 15.26 22 12s0-3.64-.07-4.93zm-2.54 8a5.73 5.73 0 01-.39 1.8A3.86 3.86 0 0116.87 19a5.73 5.73 0 01-1.81.35H8.94A5.73 5.73 0 017.13 19 3.722 3.722 0 015 16.87a5.49 5.49 0 01-.34-1.81V8.94A5.49 5.49 0 015 7.13a3.51 3.51 0 01.86-1.31A3.59 3.59 0 017.13 5a5.73 5.73 0 011.81-.35h6.12a5.73 5.73 0 011.81.35A3.722 3.722 0 0119 7.13a5.73 5.73 0 01.35 1.81V12c0 2.06.07 2.27.04 3.06zm-1.6-7.44a2.38 2.38 0 00-1.41-1.41A4 4 0 0015 6H9a4 4 0 00-1.38.26 2.38 2.38 0 00-1.41 1.36A4.27 4.27 0 006 9v6a4.27 4.27 0 00.26 1.38 2.38 2.38 0 001.41 1.41 4.27 4.27 0 001.33.26h6a4 4 0 001.38-.26 2.38 2.38 0 001.41-1.41 4 4 0 00.26-1.38V9a3.78 3.78 0 00-.26-1.38zM12 15.82A3.81 3.81 0 018.19 12 3.82 3.82 0 1112 15.82zm4-6.89a.9.9 0 010-1.79.9.9 0 010 1.79z"
                fill="#fff"
            />
            <path d="M0 0h24v24H0z" data-name="Rectangle 1491" fill="none" />
        </g>
    </svg>
);

export default Instagram;

import React from 'react';

const Practicality = props => (
    <svg width="48" height="48" {...props}>
        <defs>
            <style>{'.cls-1{fill:#0096fe}.cls-5{fill:#0000fe}'}</style>
        </defs>
        <g id="Group_4884" transform="translate(-954 -895)">
            <rect
                id="Rectangle_2489"
                width="48"
                height="32"
                fill="#0096fe"
                rx="4"
                transform="translate(954 895)"
            />
            <rect
                id="Rectangle_2491"
                width="16"
                height="4"
                fill="#0096fe"
                rx="2"
                transform="translate(970 939)"
            />
            <path
                id="Rectangle_2490"
                d="M4 0a4 4 0 0 1 4 4v4H0V4a4 4 0 0 1 4-4z"
                className="cls-5"
                transform="translate(974 919)"
            />
            <path
                id="Rectangle_2498"
                fill="#00e8fe"
                d="M0 0H8V12H0z"
                transform="translate(974 927)"
            />
            <path
                id="Rectangle_2499"
                d="M0 0h8a4 4 0 0 1-4 4 4 4 0 0 1-4-4z"
                className="cls-5"
                transform="translate(974 939)"
            />
            <path
                id="Path_1241"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2px"
                d="M769.539-590.259l8.536-7.9 11.767 2.628 14.668-10.539"
                transform="translate(191 1507.197)"
            />
        </g>
    </svg>
);
export default Practicality;

export const enUS = {
    translation: {
        main: {
            gumpDifferentialsTitle: 'Differentials',
            gumpDifferentialsSubtitle:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            gumpFreeCardTitle: 'It\'s free!',
            gumpFreeCardDescription:
                'Aproveite, de inicio vamos disponibilizar a ferramenta totalmente gratuita. Faça seu cadastro gratuitamente e aproveite todos os benefícios do Gump Form.',
            gumpIntuitiveCardTitle:
                'Intuitive',
            gumpIntuitiveCardDescription:
                'Sua landing page já está desenvolvida e o bot já está definido? Usufrua da nossa plataforma para hospedagem e colha os resultados da campanha. ',
            gumpEasyCardTitle: 'It\'s very easy',
            gumpEasyCardDescription:
                'Sua landing page já está desenvolvida e o bot já está definido? Usufrua da nossa plataforma para hospedagem e colha os resultados da campanha.',
            gumpExpenseCardTitle:
                'Custos',
            gumpExpenseCardDescription:
                'Sua landing page já está desenvolvida e o bot já está definido? Usufrua da nossa plataforma para hospedagem e colha os resultados da campanha.',
            gumpAvailableCardTitle:
                'Available',
            gumpAvailableCardDescription:
                'Sua landing page já está desenvolvida e o bot já está definido? Usufrua da nossa plataforma para hospedagem e colha os resultados da campanha.',
            gumpFirstLevelCardTitle:
                'First Level',
            gumpFirstLevelCardDescription:
                'Sua landing page já está desenvolvida e o bot já está definido? Usufrua da nossa plataforma para hospedagem e colha os resultados da campanha.',
            gumpFullTimeCardTitle:
                '24/7',
            gumpFullTimeCardDescription:
                'Sua landing page já está desenvolvida e o bot já está definido? Usufrua da nossa plataforma para hospedagem e colha os resultados da campanha.',
            gumpLoremIpsumCardTitle:
                'Lorem ipsum ',
            gumpLoremIpsumCardDescription:
                'Sua landing page já está desenvolvida e o bot já está definido? Usufrua da nossa plataforma para hospedagem e colha os resultados da campanha.',
            soon: 'Comming soon',
            resourcesTitle: 'Scale your business',
            resourcesSubtitle: 'Meet perfect solutions for your customer service',
        },
        navbar: {
            login: 'Login',
            aboutUs: 'About us',
            resources: 'Recursos',
            contact: 'Contact',
            featuredText:
                'Check our solutions and take your business to a new level.',
        },
        lead: {
            information: 'Capture informações de forma eficiente, diferente e dinâmica.',
            featuredText:
                'See how a good customer service can directly impact your business growth and the meaning of your brand to your public.',
            imInterested: 'I\'m interested',
        },
    },
};

import React from 'react';

import './NotFound.scss';
import Image404 from '../assets/images/src/image404.png';

export const NotFound = () => {
    return (
        <div className="notFound">
            <img src={Image404} alt="Página não encontrada" />
            <div className="titleNotFound">Eiiiita!</div>
            <div className="textNotFound">
                Parece que essa página não existe.
            </div>
            <div className="textNotFound">
                Mas fique tranquilo, clique no botão abaixo e seja redirecionado
                para nossa home.
            </div>
            <button
                className="button404"
                onClick={() => {
                    window.location.href = '/';
                }}>
                Leve-me para a home
            </button>
        </div>
    );
};

import React from 'react';

const ArrowLeft = props => (
    <svg width={24} height={24} {...props}>
        <g data-name="Group 2057">
            <path
                d="M14.951 11.536l-4.24-4.24a1 1 0 00-1.42 1.41l3.54 3.54-3.54 3.54a1 1 0 001.42 1.41l4.24-4.24a1 1 0 000-1.42z"
                fill="currentColor"
            />
            <path d="M0 0h24v24H0z" data-name="Rectangle 1121" fill="none" />
        </g>
    </svg>
);

export default ArrowLeft;

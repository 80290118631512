import React from 'react';

const Dots = props => (
    <svg width={339.465} height={175.885} {...props}>
        <path
            d="M337.046 2.419s-21.007 85.707-141.7 86.72S1.998 87.587 1.998 173.885"
            data-name="Path 633"
            fill="none"
            stroke="rgba(0,77,232,.32)"
            strokeLinecap="round"
            strokeWidth={4}
            strokeDasharray="0 12"
        />
    </svg>
);

export default Dots;

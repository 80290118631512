import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export const FormPage = ({ location }) => {
    useEffect(() => {
        window.mphQuestionaire.configureByURL(location.pathname.substring(1));
    }, [location]);

    return <></>;
};

FormPage.propTypes = {
    location: PropTypes.object,
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import { Button } from '../core';
// import { ArrowLeft, Wink } from '../../assets/icons';
import styles from './MainCard.module.css';

export const MainCard = ({ logo, title, description }) => {
    const { t } = useTranslation();
    return (
        <div
            className={`u-sizeFull u-sm-size6of12 u-md-size4of12 u-lg-size4of12 mv16 mv10-ns pa24 mh4-ns br16 bg-gump-card-color ${styles.card} flex flex-column justify-around`}>
            {logo}
            <h3 className="f24 gump-dark-blue fw9 mt16 mb10">{t(title)}</h3>
            <p className="f16 gump-dark-blue fw4 mt10 ">
                {t(description)}
            </p>
            {/* <div className="self-end-m self-end-l">
                <Button
                    className="w-100"
                    outlined
                    onClick={() => console.log(url)}
                    icon={disabled ? <Wink /> : <ArrowLeft />}
                    disabled={disabled}>
                    {disabled ? t('main.soon') : t(buttonDescription)}
                </Button>
            </div> */}
        </div>
    );
};

MainCard.propTypes = {
    disabled: PropTypes.bool,
    logo: PropTypes.node,
    title: PropTypes.string,
    description: PropTypes.string,
    buttonDescription: PropTypes.string,
    url: PropTypes.string,
};

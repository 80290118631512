import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { GumpMascots } from '../../assets/images';
import { ArrowLeft } from '../../assets/icons';
import circles from '../../assets/images/src/circles.svg';
import { Button } from '../core';
import styles from './Lead.module.css';

const Content = () => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src =
            'https://cdnjs.cloudflare.com/ajax/libs/sweetalert/2.1.2/sweetalert.min.js';
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
    const { t } = useTranslation();
    return (
        <div
            className={`u-sizeFull u-sm-size10of12 u-md-size5of12 u-lg-size5of12 ${styles.marginTop} ph24`}>
            <h1 className="f36 f48-ns fw9 white lh-title mv10">
                {t('lead.levelUp')}
            </h1>
            <p
                className={`m0 f20 f24-ns white lh-copy w-100 w-80-ns mv10 ${styles.paddingTop} ${styles.paddingBottom}`}>
                {t('lead.featuredText')}
            </p>
            <Button
                className="w-100 w-auto-ns"
                color="gump-orange"
                icon={<ArrowLeft />}
                onClick={() => (window.location.href = 'https://app.gump.ai/')}>
                {t('lead.imInterested')}
            </Button>
        </div>
    );
};

const MobileLead = () => {
    return (
        <div
            className={`Grid limit flex ${styles.imageWrapper} ${styles.backgroundImage}`}>
            <div className="u-size0of12 u-sm-size1of12 u-md-size1of12 u-lg-size1of12"></div>
            <Content />
            <div className="u-size0of12 u-sm-size1of12 u-md-size1of12 u-lg-size1of12"></div>
        </div>
    );
};

export const Lead = () => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery({
        query: '(max-width: 1023px)',
    });

    if (isMobile) {
        return <MobileLead />;
    }

    return (
        <div className={`Grid limit flex relative ${styles.imageWrapper}`}>
            <img
                src={circles}
                className={`absolute z--1 ${styles.circles}`}
                alt={t('lead.circlesAlt')}
            />
            <div className="u-size0of12 u-sm-size1of12 u-md-size1of12 u-lg-size1of12"></div>
            <Content />
            <div
                className={`u-size5of12 dn dn-m flex-l justify-center ${styles.marginTop}`}>
                <GumpMascots />
            </div>
            <div className="u-size0of12 u-sm-size1of12 u-md-size1of12 u-lg-size1of12"></div>
        </div>
    );
};

import React from 'react';

const Facebook = props => (
    <svg width={24} height={24} {...props}>
        <g data-name="Group 4463">
            <path
                d="M20.9 2H3.1A1.1 1.1 0 002 3.1v17.8A1.1 1.1 0 003.1 22h9.58v-7.75h-2.6v-3h2.6V9a3.64 3.64 0 013.88-4 20.26 20.26 0 012.33.12v2.7H17.3c-1.26 0-1.5.6-1.5 1.47v1.93h3l-.39 3H15.8V22h5.1a1.1 1.1 0 001.1-1.1V3.1A1.1 1.1 0 0020.9 2z"
                data-name="facebook (1)"
                fill="#fff"
            />
            <path d="M0 0h24v24H0z" data-name="Rectangle 1490" fill="none" />
        </g>
    </svg>
);

export default Facebook;

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './Button.module.css';

export const Button = ({
    outlined,
    disabled,
    children,
    onClick,
    className,
    color,
    icon,
    submit,
}) => {
    const variantStyle = cx(
        'white f14 pointer fw6 ttu br-pill flex items-center',
        {
            [`gump-blue bg-transparent ${styles.outlinedButton} b--${color} ${color}`]:
                outlined && !disabled,
            [`bw0 white f14 pointer bg-gump-blue bg-${color}`]:
                !outlined && !disabled,
            'bg-gump-light-gray gump-mid-gray bn default-pointer': disabled,
            'ph24 pv16 justify-center': !icon,
            'pa10 pl16 justify-between justify-center-ns': icon,
        },
        styles.buttonHover,
        className
    );
    const textStyle = cx('ma0', {
        mt2: !icon,
        'mt0 w-100 ml10 ml0-ns pl0-ns': icon,
    });
    return (
        <button
            tabIndex="-1"
            type={submit ? 'submit' : 'button'}
            className={variantStyle}
            disabled={disabled}
            onClick={onClick}>
            <p className={textStyle}>{children}</p>
            {icon}
        </button>
    );
};

Button.propTypes = {
    outlined: PropTypes.bool,
    disabled: PropTypes.bool,
    icon: PropTypes.node,
    children: PropTypes.node,
    onClick: PropTypes.func,
    color: PropTypes.string,
    className: PropTypes.string,
    submit: PropTypes.bool,
};

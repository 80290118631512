import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import PropTypes from 'prop-types';

export const Feature = ({ image, title, content, textLeft, textFloat, className }) => {
    const { t } = useTranslation();
    // const textAlignment = cx({
    //     'flex flex-column tc tl-ns': textLeft,
    //     'flex flex-column tc tr-ns': !textLeft,
    // });
    const customClass = cx(
        'flex mt0 flex-row-ns justify-center justify-start-ns items-center na1-ns',
        {
            'flex-column-reverse flex-row-reverse-ns': textLeft,
            'flex-column-reverse': !textLeft,
        },
        className
    );
    const contentAligment = cx('f16 gump-dark-blue mt0 mb32-ns w-70 ', {
        'self-center self-start-ns': textLeft,
        'self-center self-end-ns': !textLeft,
    });
    return (
        <div className={customClass}>
            <img src={image} className="na2" alt={t(title)} />
            <div style={{textAlign: textFloat}}>
                <h3 className="f24 gump-dark-blue mb10 fw9">{t(title)}</h3>
                <p className={contentAligment} style={{float: textFloat}}>{t(content)}</p>
            </div>
        </div>
    );
};

Feature.propTypes = {
    image: PropTypes.node,
    title: PropTypes.string,
    content: PropTypes.string,
    textLeft: PropTypes.bool,
    textFloat: PropTypes.string,
    className: PropTypes.string,
};

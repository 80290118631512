import React from 'react';

const MorpheusLogo = props => (
    <svg
        id="prefix__Group_155"
        width={203.294}
        height={40}
        data-name="Group 155"
        {...props}>
        <defs>
            <style>{'.prefix__cls-billyocean{fill:#adbcd8}'}</style>
        </defs>
        <g id="prefix__Group_93" data-name="Group 93">
            <g id="prefix__Group_86" data-name="Group 86">
                <circle
                    id="prefix__Ellipse_25"
                    cx={4}
                    cy={4}
                    className="prefix__cls-billyocean"
                    data-name="Ellipse 25"
                    r={4}
                />
                <circle
                    id="prefix__Ellipse_45"
                    cx={4}
                    cy={4}
                    className="prefix__cls-billyocean"
                    data-name="Ellipse 45"
                    transform="translate(32)"
                    r={4}
                />
            </g>
            <g
                id="prefix__Group_87"
                data-name="Group 87"
                transform="translate(0 16)">
                <circle
                    id="prefix__Ellipse_27"
                    cx={4}
                    cy={4}
                    className="prefix__cls-billyocean"
                    data-name="Ellipse 27"
                    r={4}
                />
                <circle
                    id="prefix__Ellipse_37"
                    cx={4}
                    cy={4}
                    className="prefix__cls-billyocean"
                    data-name="Ellipse 37"
                    transform="translate(16)"
                    r={4}
                />
                <circle
                    id="prefix__Ellipse_47"
                    cx={4}
                    cy={4}
                    className="prefix__cls-billyocean"
                    data-name="Ellipse 47"
                    transform="translate(32)"
                    r={4}
                />
            </g>
            <g
                id="prefix__Group_88"
                data-name="Group 88"
                transform="translate(0 32)">
                <circle
                    id="prefix__Ellipse_29"
                    cx={4}
                    cy={4}
                    className="prefix__cls-billyocean"
                    data-name="Ellipse 29"
                    r={4}
                />
                <circle
                    id="prefix__Ellipse_39"
                    cx={4}
                    cy={4}
                    className="prefix__cls-billyocean"
                    data-name="Ellipse 39"
                    transform="translate(16)"
                    r={4}
                />
                <circle
                    id="prefix__Ellipse_49"
                    cx={4}
                    cy={4}
                    className="prefix__cls-billyocean"
                    data-name="Ellipse 49"
                    transform="translate(32)"
                    r={4}
                />
            </g>
        </g>
        <g
            id="prefix__Group_92"
            data-name="Group 92"
            transform="translate(64.001 9.187)">
            <g id="prefix__Group_90" data-name="Group 90">
                <path
                    id="prefix__Path_22"
                    d="M902.59 544.85v-20.414h3.21l7.706 11.1 7.652-11.1h3.259v20.414h-4.557v-10.8l-5.553 7.927h-1.658l-5.551-7.927v10.8z"
                    className="prefix__cls-billyocean"
                    data-name="Path 22"
                    transform="translate(-902.59 -523.829)"
                />
                <path
                    id="prefix__Path_23"
                    d="M942.62 545.524a7.82 7.82 0 01-2.265-5.69 7.706 7.706 0 012.265-5.662 8.652 8.652 0 0111.795 0 7.721 7.721 0 012.294 5.662 7.835 7.835 0 01-2.294 5.69 8.734 8.734 0 01-11.795 0zm8.619-8.481a3.8 3.8 0 00-5.47 0 3.774 3.774 0 00-1.1 2.762 3.923 3.923 0 001.1 2.845 3.869 3.869 0 005.47 0 3.937 3.937 0 001.134-2.845 3.79 3.79 0 00-1.134-2.762z"
                    className="prefix__cls-billyocean"
                    data-name="Path 23"
                    transform="translate(-915.591 -526.381)"
                />
                <path
                    id="prefix__Path_24"
                    d="M969.353 547.4v-15.135h4.147v1.961a4.569 4.569 0 014.116-2.376 5.48 5.48 0 011.878.36l-.36 4.143a5.781 5.781 0 00-1.9-.332c-2.128 0-3.536 1.381-3.536 4.475v6.904z"
                    className="prefix__cls-billyocean"
                    data-name="Path 24"
                    transform="translate(-925.574 -526.381)"
                />
                <path
                    id="prefix__Path_25"
                    d="M1001.958 534.213a7.947 7.947 0 012.1 5.607 8.1 8.1 0 01-2.128 5.664 6.87 6.87 0 01-5.3 2.32 6.057 6.057 0 01-4.557-1.933v7.79h-4.336V532.28h4.28v1.685a5.976 5.976 0 014.613-2.072 6.9 6.9 0 015.328 2.32zm-3.372 2.845a3.835 3.835 0 00-5.5 0 3.882 3.882 0 00-1.077 2.79 3.829 3.829 0 107.652 0 3.87 3.87 0 00-1.075-2.79z"
                    className="prefix__cls-billyocean"
                    data-name="Path 25"
                    transform="translate(-931.9 -526.396)"
                />
                <path
                    id="prefix__Path_26"
                    d="M1016.729 544.531V523.51h4.226v7.9a5.5 5.5 0 014.78-2.431 5.8 5.8 0 014.143 1.631 6.176 6.176 0 011.711 4.668v9.253h-4.337v-8.121c0-2.21-1.131-3.454-2.928-3.454a3.235 3.235 0 00-3.259 3.564v8.01z"
                    className="prefix__cls-billyocean"
                    data-name="Path 26"
                    transform="translate(-941.883 -523.51)"
                />
                <path
                    id="prefix__Path_27"
                    d="M1058.779 541.313h-11.132c.525 1.906 1.906 2.845 4.116 2.845a8.309 8.309 0 004.724-1.464l1.712 2.955a10.612 10.612 0 01-6.629 2.1 8.067 8.067 0 01-6.16-2.293 7.986 7.986 0 01-2.126-5.636 7.745 7.745 0 012.208-5.662 8.456 8.456 0 0111.272-.222 7.269 7.269 0 012.155 5.553 15.731 15.731 0 01-.14 1.824zm-11.159-3.094h7.126a3.425 3.425 0 00-3.48-2.818 3.638 3.638 0 00-3.646 2.818z"
                    className="prefix__cls-billyocean"
                    data-name="Path 27"
                    transform="translate(-951.025 -526.396)"
                />
                <path
                    id="prefix__Path_28"
                    d="M1070.889 541.129v-8.646h4.336v8.673a2.765 2.765 0 105.525 0v-8.673h4.336v8.646c0 4.391-2.679 6.878-7.1 6.878-4.386 0-7.097-2.542-7.097-6.878z"
                    className="prefix__cls-billyocean"
                    data-name="Path 28"
                    transform="translate(-960.528 -526.599)"
                />
                <path
                    id="prefix__Path_29"
                    d="M1095.675 544.984l2.125-2.818a5.938 5.938 0 004.337 1.99c1.077 0 1.851-.47 1.851-1.216 0-.442-.3-.8-.938-1.106-.222-.11-.83-.33-1.8-.662-.965-.359-1.628-.607-1.961-.774a4.241 4.241 0 01-2.705-4 4.1 4.1 0 011.6-3.37 6.738 6.738 0 014.309-1.3 7.573 7.573 0 015.47 2.1l-2.018 2.818a5.5 5.5 0 00-3.536-1.409c-.967 0-1.574.442-1.574 1.077 0 .552.443.967 1.354 1.271a23.662 23.662 0 013.592 1.381 4.216 4.216 0 012.569 3.868 4.431 4.431 0 01-1.714 3.536 6.806 6.806 0 01-4.5 1.408 8.2 8.2 0 01-6.461-2.794z"
                    className="prefix__cls-billyocean"
                    data-name="Path 29"
                    transform="translate(-969.061 -526.338)"
                />
            </g>
            <g
                id="prefix__Group_91"
                data-name="Group 91"
                transform="translate(135.904)">
                <path
                    id="prefix__Path_30"
                    d="M1111.536 523.51a1.7 1.7 0 11-1.2.5 1.629 1.629 0 011.2-.5zm0 3.044a1.35 1.35 0 10-.953-.4 1.3 1.3 0 00.953.4zm.354-1.151l.367.635h-.367l-.34-.579h-.282v.579h-.325v-1.7h.685a.555.555 0 01.586.565.5.5 0 01-.324.497zm-.622-.791v.579h.282q.34 0 .34-.3c0-.191-.113-.283-.34-.283z"
                    className="prefix__cls-billyocean"
                    data-name="Path 30"
                    transform="translate(-1109.841 -523.51)"
                />
            </g>
        </g>
    </svg>
);

export default MorpheusLogo;

import React from 'react';
import PropTypes from 'prop-types';

export const Layout = ({ children }) => {
    return <section className="sans-serif">{children}</section>;
};

Layout.propTypes = {
    children: PropTypes.node,
};

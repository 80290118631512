import React from 'react';

const Quotes = props => (
    <svg width={27.6} height={22.837} {...props}>
        <path
            d="M6.473 22.837a5.5 5.5 0 01-4.855-2.351A11.464 11.464 0 010 13.922a14.337 14.337 0 012.32-8.091A17.165 17.165 0 019.526-.004l2.2 4.519a11.129 11.129 0 00-4.4 3.145 7.225 7.225 0 00-1.464 4.613 2.143 2.143 0 01.611-.061 5.44 5.44 0 013.724 1.344 4.63 4.63 0 011.527 3.663 5.66 5.66 0 01-1.466 4.092 5.014 5.014 0 01-3.785 1.526zm15.876 0a5.5 5.5 0 01-4.855-2.351 11.464 11.464 0 01-1.618-6.564 14.337 14.337 0 012.32-8.091 17.165 17.165 0 017.206-5.835l2.2 4.519a11.129 11.129 0 00-4.4 3.145 7.225 7.225 0 00-1.465 4.61 2.143 2.143 0 01.611-.061 5.44 5.44 0 013.725 1.347A4.63 4.63 0 0127.6 17.22a5.66 5.66 0 01-1.465 4.091 5.014 5.014 0 01-3.783 1.526z"
            data-name="Path 637"
            fill="#20396a"
        />
    </svg>
);

export default Quotes;

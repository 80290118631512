import React from 'react';

const Linkedin = props => (
    <svg width={24} height={24} {...props}>
        <g data-name="Group 4462">
            <path
                d="M20.41 2H3.47A1.45 1.45 0 002 3.43v17.14A1.45 1.45 0 003.47 22h16.94a1.45 1.45 0 001.47-1.43V3.43A1.45 1.45 0 0020.41 2zM8.03 18.74h-3v-9h3zM6.53 8.48a1.56 1.56 0 110-3.12 1.57 1.57 0 110 3.12zm12.32 10.26h-3v-4.83c0-1.21-.43-2-1.52-2A1.65 1.65 0 0012.79 13a2 2 0 00-.1.73v5h-3v-9h3V11a3 3 0 012.71-1.5c2 0 3.45 1.29 3.45 4.06z"
                fill="#fff"
            />
            <path d="M0 0h24v24H0z" data-name="Rectangle 1489" fill="none" />
        </g>
    </svg>
);

export default Linkedin;

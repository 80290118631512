import React from 'react';

const Simplicity = props => (
    <svg width="48" height="48" {...props}>
        <defs>
            <style>
                {'.cls-5{fill:#0000fe}.cls-6{fill:#00e8fe}.cls-4{fill:#fff}'}
            </style>
        </defs>
        <g id="Group_4883" transform="translate(-558 -895)">
            <rect
                id="Rectangle_2482"
                width="32"
                height="48"
                fill="#0096fe"
                rx="4"
                transform="translate(574 895)"
            />
            <path
                id="Rectangle_2483"
                d="M0 0h14a2 2 0 0 1 2 2 2 2 0 0 1-2 2H0V0z"
                className="cls-5"
                transform="translate(574 908)"
            />
            <path
                id="Rectangle_2496"
                d="M2 0h14v4H2a2 2 0 0 1-2-2 2 2 0 0 1 2-2z"
                className="cls-6"
                transform="translate(558 908)"
            />
            <rect
                id="Rectangle_2484"
                width="16"
                height="4"
                className="cls-4"
                rx="2"
                transform="translate(582 914)"
            />
            <rect
                id="Rectangle_2485"
                width="8"
                height="4"
                className="cls-4"
                rx="2"
                transform="translate(576 920)"
            />
            <path
                id="Rectangle_2486"
                d="M0 0h4a2 2 0 0 1 2 2 2 2 0 0 1-2 2H0V0z"
                className="cls-5"
                transform="translate(574 926)"
            />
            <path
                id="Rectangle_2497"
                d="M2 0h5v4H2a2 2 0 0 1-2-2 2 2 0 0 1 2-2z"
                className="cls-6"
                transform="translate(567 926)"
            />
            <path
                id="Rectangle_2487"
                d="M0 0h32v2a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V0z"
                className="cls-5"
                transform="translate(574 937)"
            />
        </g>
    </svg>
);
export default Simplicity;

export const ptBR = {
    translation: {
        main: {
            gumpDifferentialsTitle: 'Desenvolvido para você',
            gumpDifferentialsSubtitle: 'Veja como podemos lhe ajudar',
            gumpFreeCardTitle: 'Gratuidade',
            gumpFreeCardDescription:
                'E sem pegadinhas! Gump Form está disponível totalmente gratuito. Cadastre-se e aproveite todos os benefícios oferecidos.',
            gumpIntuitiveCardTitle: 'Simplicidade',
            gumpIntuitiveCardDescription:
                'Sabe aqueles formulários chatos utilizados para captura de dados? Esqueça-os! Gump Form vai revolucionar suas pesquisas.',
            gumpEasyCardTitle: 'Praticidade',
            gumpEasyCardDescription:
                'Gump Form permite  capturar dados e coletar métricas importantes para pesquisas. Otimize suas campanhas e acompanhe os resultados.',
            soon: 'Em breve',
            resourcesTitle: 'Otimize seu negócio',
            resourcesSubtitle:
                'Conheça um pouco sobre as soluções ideais para seu atendimento',
            dataCollectFeatureTitle: 'Coleta de dados',
            dataCollectFeatureDescription:
                'Customização intuitiva para a coleta de informações dos usuários',
            dataFeatureTitle: 'Métricas de dados',
            dataFeatureDescription:
                'Sem complicações no acompanhamento de seus dados',
            reportsFeatureTitle: 'Relatórios',
            reportsFeatureDescription:
                'Dinamismo na obtenção dos dados desejados',
            brandsTitle: 'Empresas que confiam na gente',
            brandsDescription: 'Conheça alguns clientes Gump:',
            testimonialsTitle: 'O que dizem sobre nós',
            testimonialsDescription:
                'Confira alguns depoimentos de quem usa e recomenda o Gump:',
        },
        navbar: {
            login: 'Entrar',
            aboutUs: 'O que somos',
            contact: 'Contato',
            featuredText:
                'CONHEÇA NOSSAS SOLUÇÕES E LEVE SEU NEGÓCIO A UM OUTRO PATAMAR.',
            differentials: 'Diferenciais',
        },
        lead: {
            levelUp: 'Capture dados de forma eficiente e dinâmica',
            featuredText:
                'Formulário chato para captura de dados? Ficou no passado! Gump Form veio para mudar a forma como você faz pesquisas.',
            imInterested: 'Quero conhecer',
        },
    },
};

import React from 'react';

const GumpMascots = props => (
    <svg width={391} height={487.716} {...props}>
        <defs>
            <filter
                id="prefix__Union_2"
                width={221.08}
                height={246.127}
                x={80.5}
                y={77.146}
                filterUnits="userSpaceOnUse">
                <feOffset dy={2} />
                <feGaussianBlur result="blur" stdDeviation={8} />
                <feFlood floodColor="#004de8" floodOpacity={0.078} />
                <feComposite in2="blur" operator="in" />
                <feComposite in="SourceGraphic" />
            </filter>
            <style>
                {
                    '.prefix__cls-4{fill:#004de8},.prefix__cls-gleidson2{fill:#004de8}.prefix__cls-gleidson2{opacity:.2}.prefix__cls-gis5{fill:#20396a}.prefix__cls-frav11{fill:#c5d6fb}'
                }
            </style>
        </defs>
        <g
            id="prefix__Group_4430"
            data-name="Group 4430"
            transform="translate(-829 -209.355)">
            <g
                id="prefix__Group_2162"
                data-name="Group 2162"
                transform="rotate(-30 972.37 -1628.572)">
                <path
                    id="prefix__Rectangle_989_1_"
                    d="M34.46 43.72a5.788 5.788 0 018.17 0l18.35 18.35a5.77 5.77 0 010 8.17 5.788 5.788 0 01-8.17 0L34.46 51.88a5.758 5.758 0 010-8.16z"
                    opacity={0.4}
                    fill="#004de8"
                />
                <path
                    id="prefix__Rectangle_990_1_"
                    d="M34.46 43.71l18.35-18.35a5.77 5.77 0 018.17 0 5.788 5.788 0 010 8.17L42.63 51.88a5.77 5.77 0 01-8.17 0 5.77 5.77 0 010-8.17z"
                    className="prefix__cls-gleidson2"
                />
                <path
                    id="prefix__Rectangle_991_1_"
                    d="M52.81 62.06l18.35-18.35a5.77 5.77 0 018.17 0 5.788 5.788 0 010 8.17L60.97 70.23a5.77 5.77 0 01-8.17 0 5.779 5.779 0 01.01-8.17z"
                    opacity={0.8}
                    fill="#004de8"
                />
                <path
                    id="prefix__Rectangle_992_1_"
                    d="M52.81 25.37a5.788 5.788 0 018.17 0l18.35 18.35a5.77 5.77 0 010 8.17 5.788 5.788 0 01-8.17 0L52.81 33.53a5.758 5.758 0 010-8.16z"
                    opacity={0.4}
                    fill="#004de8"
                />
                <path
                    id="prefix__Path_52_1_"
                    d="M90.91 8.61a5.177 5.177 0 015.17 5.17v18.57a8.648 8.648 0 002.52 6.09l9.36 9.36-9.36 9.36a8.648 8.648 0 00-2.52 6.09v18.57a5.177 5.177 0 01-5.17 5.17H22.87a5.177 5.177 0 01-5.17-5.17V13.78a5.177 5.177 0 015.17-5.17h68.04m0-8.61H22.87A13.781 13.781 0 009.09 13.78v68.04A13.781 13.781 0 0022.87 95.6h68.04a13.781 13.781 0 0013.78-13.78V63.25l10.58-10.58a6.887 6.887 0 000-9.74l-10.58-10.58V13.78A13.781 13.781 0 0090.91 0z"
                    className="prefix__cls-4"
                />
            </g>
            <path
                id="prefix__Path_563"
                d="M280.673 461.089a57.6 57.6 0 01-21.279-4.57 3.5 3.5 0 012.871-6.389c.191.084 19.193 8.423 31.237.617 6.988-4.531 10.532-13.877 10.532-27.779a3.5 3.5 0 117.006 0c0 16.444-4.627 27.774-13.752 33.675a30.256 30.256 0 01-16.615 4.446z"
                className="prefix__cls-gis5"
                data-name="Path 563"
                transform="translate(699.468 103.119)"
            />
            <g
                id="prefix__Group_2024"
                data-name="Group 2024"
                transform="translate(840 490.605)">
                <ellipse
                    id="prefix__Ellipse_120"
                    cx={64.219}
                    cy={10.509}
                    data-name="Ellipse 120"
                    rx={64.219}
                    ry={10.509}
                    transform="translate(30.393 155.746)"
                    opacity={0.997}
                    fill="rgba(173,188,216,.32)"
                />
                <g
                    id="prefix__Group_2019"
                    data-name="Group 2019"
                    transform="translate(18.97)">
                    <g
                        id="prefix__Group_2018"
                        data-name="Group 2018"
                        transform="translate(28.328 26.749)">
                        <circle
                            id="prefix__Ellipse_121"
                            cx={5.214}
                            cy={5.214}
                            className="prefix__cls-gleidson2"
                            data-name="Ellipse 121"
                            transform="translate(0 6.649)"
                            r={5.214}
                        />
                        <circle
                            id="prefix__Ellipse_122"
                            cx={5.214}
                            cy={5.214}
                            data-name="Ellipse 122"
                            transform="translate(16.37 3.324)"
                            opacity={0.5}
                            fill="#004de8"
                            r={5.214}
                        />
                        <circle
                            id="prefix__Ellipse_123"
                            cx={5.214}
                            cy={5.214}
                            className="prefix__cls-4"
                            data-name="Ellipse 123"
                            transform="translate(32.74)"
                            r={5.214}
                        />
                        <path
                            id="prefix__Path_558"
                            d="M216.7 445.02a3.864 3.864 0 00-4.208 3.484c-.038.4-.091.787-.159 1.175a15.355 15.355 0 01-28.037 5.693q-.321-.5-.6-1.02a3.862 3.862 0 00-6.8 3.666 23.082 23.082 0 0043.29-8.79 3.863 3.863 0 00-3.486-4.208z"
                            className="prefix__cls-4"
                            data-name="Path 558"
                            transform="translate(-171.719 -419.321)"
                        />
                    </g>
                    <path
                        id="prefix__Path_559"
                        d="M214.237 388.212a5.149 5.149 0 016.063 4.017l3.678 18.112a8.574 8.574 0 003.666 5.44l10.986 7.277-7.277 10.986a8.57 8.57 0 00-1.254 6.44l3.678 18.111a5.149 5.149 0 01-4.017 6.064l-66.366 13.477a5.149 5.149 0 01-6.063-4.017l-13.477-66.366a5.149 5.149 0 014.017-6.064l66.366-13.477m-1.707-8.4l-66.366 13.477a13.715 13.715 0 00-10.711 16.17l13.477 66.366a13.715 13.715 0 0016.17 10.712l66.366-13.476a13.716 13.716 0 0010.712-16.171l-3.678-18.111 8.225-12.416a6.858 6.858 0 00-1.93-9.5l-12.416-8.224-3.679-18.115a13.716 13.716 0 00-16.171-10.711z"
                        className="prefix__cls-4"
                        data-name="Path 559"
                        transform="translate(-135.176 -379.535)"
                    />
                </g>
                <g
                    id="prefix__Group_2020"
                    data-name="Group 2020"
                    transform="translate(52.577 98.534)">
                    <path
                        id="prefix__Path_560"
                        d="M180.648 570.292a3.5 3.5 0 01-3.485-3.905l7.006-60.717a3.5 3.5 0 016.96.8l-7.006 60.717a3.5 3.5 0 01-3.475 3.105z"
                        className="prefix__cls-gis5"
                        data-name="Path 560"
                        transform="translate(-177.14 -502.57)"
                    />
                </g>
                <g
                    id="prefix__Group_2021"
                    data-name="Group 2021"
                    transform="translate(0 64.671)">
                    <path
                        id="prefix__Path_561"
                        d="M117.36 528.009a3.5 3.5 0 01-3.36-2.514c-12.3-41.814 24.537-64.485 24.91-64.708a3.5 3.5 0 013.61 6c-1.334.807-32.549 20.181-21.8 56.73a3.5 3.5 0 01-3.362 4.49z"
                        className="prefix__cls-gis5"
                        data-name="Path 561"
                        transform="translate(-111.489 -460.287)"
                    />
                </g>
                <g
                    id="prefix__Group_2022"
                    data-name="Group 2022"
                    transform="translate(100.458 90.359)">
                    <path
                        id="prefix__Path_562"
                        d="M270.783 553.079h-.178a3.5 3.5 0 01-3.324-3.674c2.148-42.965-26.392-49.842-27.609-50.114a3.5 3.5 0 011.483-6.847c.356.076 35.578 8.2 33.122 57.311a3.5 3.5 0 01-3.494 3.324z"
                        className="prefix__cls-gis5"
                        data-name="Path 562"
                        transform="translate(-236.926 -492.363)"
                    />
                </g>
            </g>
            <ellipse
                id="prefix__Ellipse_124"
                cx={71.226}
                cy={11.655}
                data-name="Ellipse 124"
                rx={71.226}
                ry={11.655}
                transform="translate(1059.548 655.76)"
                fill="rgba(173,188,216,.32)"
            />
            <g
                id="prefix__Group_2026"
                data-name="Group 2026"
                transform="translate(1063.22 516.341)">
                <path
                    id="prefix__Path_564"
                    d="M487.711 437.327a5.15 5.15 0 013.7 6.265l-4.618 17.894a8.574 8.574 0 00.917 6.5l6.694 11.351-11.35 6.694a8.57 8.57 0 00-3.946 5.242l-4.617 17.894a5.149 5.149 0 01-6.265 3.7l-65.573-16.919a5.149 5.149 0 01-3.7-6.265l16.92-65.583a5.148 5.148 0 016.265-3.7l65.573 16.919m2.141-8.3l-65.572-16.911a13.715 13.715 0 00-16.708 9.854l-16.919 65.573a13.715 13.715 0 009.855 16.707l65.572 16.919a13.715 13.715 0 0016.708-9.854l4.617-17.894 12.827-7.566a6.858 6.858 0 002.422-9.391l-7.565-12.827 4.617-17.894a13.715 13.715 0 00-9.854-16.707z"
                    className="prefix__cls-4"
                    data-name="Path 564"
                    transform="translate(-390.215 -411.67)"
                />
                <g
                    id="prefix__Group_2025"
                    data-name="Group 2025"
                    transform="translate(31.041 31.024)">
                    <path
                        id="prefix__Path_565"
                        d="M456.332 461.133a13.654 13.654 0 11-16.632 9.81 13.67 13.67 0 0116.632-9.81m2.57-9.96a23.941 23.941 0 1017.2 29.163 23.941 23.941 0 00-17.2-29.163z"
                        className="prefix__cls-gleidson2"
                        data-name="Path 565"
                        transform="translate(-428.974 -450.408)"
                    />
                    <circle
                        id="prefix__Ellipse_125"
                        cx={5.214}
                        cy={5.214}
                        className="prefix__cls-4"
                        data-name="Ellipse 125"
                        transform="translate(18.648 18.711)"
                        r={5.214}
                    />
                </g>
            </g>
            <g
                id="prefix__Group_2027"
                data-name="Group 2027"
                transform="translate(1045.536 506.235)">
                <path
                    id="prefix__Path_566"
                    d="M396.729 464a3.509 3.509 0 01-3.218-2.112l-25.089-57.937a3.5 3.5 0 016.429-2.785l25.091 57.934a3.507 3.507 0 01-3.213 4.9z"
                    className="prefix__cls-gis5"
                    data-name="Path 566"
                    transform="translate(-368.133 -399.051)"
                />
            </g>
            <g
                id="prefix__Group_2028"
                data-name="Group 2028"
                transform="translate(1078.23 604.317)">
                <path
                    id="prefix__Path_567"
                    d="M412.46 584.574a3.5 3.5 0 01-3.5-3.5v-56.049a3.5 3.5 0 117.006 0v56.046a3.5 3.5 0 01-3.506 3.503z"
                    className="prefix__cls-gis5"
                    data-name="Path 567"
                    transform="translate(-408.957 -521.522)"
                />
            </g>
            <g
                id="prefix__Group_2029"
                data-name="Group 2029"
                transform="translate(1124.935 615.992)">
                <path
                    id="prefix__Path_568"
                    d="M516.316 587.477a3.492 3.492 0 01-2.444-.994l-45.538-44.37a3.5 3.5 0 014.889-5.017l45.538 44.37a3.5 3.5 0 01-2.445 6.011z"
                    className="prefix__cls-gis5"
                    data-name="Path 568"
                    transform="translate(-467.275 -536.1)"
                />
            </g>
            <g
                id="prefix__Group_2030"
                data-name="Group 2030"
                transform="translate(1150.624 596.662)">
                <path
                    id="prefix__Path_569"
                    d="M547.224 538.3a3.506 3.506 0 01-3.3-2.323c-2.91-8.153-7.462-13.43-13.528-15.686-11.678-4.337-25.667 3.46-25.808 3.54a3.5 3.5 0 01-3.471-6.087c.684-.392 16.9-9.509 31.681-4.034 8.116 3 14.08 9.7 17.726 19.909a3.506 3.506 0 01-2.124 4.481 3.556 3.556 0 01-1.176.2z"
                    className="prefix__cls-gis5"
                    data-name="Path 569"
                    transform="translate(-499.352 -511.964)"
                />
            </g>
            <path
                id="prefix__Rectangle_1107"
                d="M0 0h383v383H0z"
                data-name="Rectangle 1107"
                transform="translate(829 311)"
                fill="none"
            />
            <g transform="translate(829 209.35)" filter="url(#prefix__Union_2)">
                <g id="prefix__Union_2-2" data-name="Union 2" fill="#fff">
                    <path
                        d="M322.142 3733.456l-15.185-15.186H261a15.5 15.5 0 01-15.5-15.5V3566a15.5 15.5 0 0115.5-15.5h128.08a15.5 15.5 0 0115.5 15.5v136.77a15.5 15.5 0 01-15.5 15.5h-46.1l-15.18 15.186a4 4 0 01-5.657 0z"
                        transform="translate(-134 -3444.35)"
                    />
                    <path
                        d="M324.97 3734.627a3.99 3.99 0 002.829-1.17l15.186-15.187h46.095c8.56 0 15.5-6.94 15.5-15.5V3566c0-8.56-6.94-15.5-15.5-15.5H261c-8.56 0-15.5 6.94-15.5 15.5v136.77c0 8.56 6.94 15.5 15.5 15.5h45.956l15.186 15.186a3.99 3.99 0 002.829 1.171m0 7a10.93 10.93 0 01-7.777-3.22l-13.137-13.137H261c-12.406 0-22.5-10.093-22.5-22.5V3566c0-12.407 10.094-22.5 22.5-22.5h128.08c12.407 0 22.5 10.093 22.5 22.5v136.77c0 12.407-10.093 22.5-22.5 22.5h-43.195l-13.136 13.136a10.928 10.928 0 01-7.778 3.221z"
                        transform="translate(-134 -3444.35)"
                        fill="rgba(0,77,232,.08)"
                    />
                </g>
            </g>
            <rect
                id="prefix__Rectangle_1099"
                width={47.418}
                height={10.411}
                className="prefix__cls-frav11"
                data-name="Rectangle 1099"
                rx={5.206}
                transform="translate(993 341)"
            />
            <rect
                id="prefix__Rectangle_1100"
                width={81.822}
                height={10.411}
                className="prefix__cls-frav11"
                data-name="Rectangle 1100"
                rx={5.206}
                transform="translate(993 361.822)"
            />
            <rect
                id="prefix__Rectangle_1101"
                width={81.639}
                height={10.411}
                className="prefix__cls-frav11"
                data-name="Rectangle 1101"
                rx={5.206}
                transform="translate(993.198 424.29)"
            />
            <rect
                id="prefix__Rectangle_1102"
                width={26.758}
                height={10.411}
                className="prefix__cls-4"
                data-name="Rectangle 1102"
                rx={5.206}
                transform="translate(1048 382.645)"
            />
            <rect
                id="prefix__Rectangle_1103"
                width={54.07}
                height={10.411}
                className="prefix__cls-4"
                data-name="Rectangle 1103"
                rx={5.206}
                transform="translate(1020.688 403.467)"
            />
            <rect
                id="prefix__Rectangle_1104"
                width={81.758}
                height={10.411}
                className="prefix__cls-4"
                data-name="Rectangle 1104"
                rx={5.206}
                transform="translate(993 446.714)"
            />
            <circle
                id="prefix__Ellipse_162"
                cx={9}
                cy={9}
                r={9}
                className="prefix__cls-frav11"
                data-name="Ellipse 162"
                transform="translate(966.254 337.177)"
            />
            <circle
                id="prefix__Ellipse_164"
                cx={9}
                cy={9}
                r={9}
                className="prefix__cls-frav11"
                data-name="Ellipse 164"
                transform="translate(966.254 420.177)"
            />
            <g
                id="prefix__Group_2165"
                data-name="Group 2165"
                transform="rotate(-57 796.017 -714.39)">
                <path
                    id="prefix__Path_658"
                    d="M50.79 88.309a3.412 3.412 0 00-1.25.23l-34 13a3.5 3.5 0 00-.85 6.07l20 15a3.5 3.5 0 104.2-5.6l-14.93-11.2 28.08-10.74a3.494 3.494 0 00-1.25-6.76z"
                    className="prefix__cls-gis5"
                    data-name="Path 658"
                    transform="translate(-13.285 -88.309)"
                />
            </g>
            <g
                id="prefix__Group_4425"
                data-name="Group 4425"
                transform="rotate(51 243.152 1259.21)">
                <path
                    id="prefix__Path_566-2"
                    d="M396.729 464a3.509 3.509 0 01-3.218-2.112l-25.089-57.937a3.5 3.5 0 016.429-2.785l25.091 57.934a3.507 3.507 0 01-3.213 4.9z"
                    className="prefix__cls-gis5"
                    data-name="Path 566"
                    transform="translate(-368.133 -399.051)"
                />
            </g>
        </g>
    </svg>
);

export default GumpMascots;

import React from 'react';
import { GumpLogo_White } from '../../assets/images';
import { Facebook, Instagram, Linkedin } from '../../assets/icons';
import styles from './Footer.module.css';

export const Footer = () => {
    return (
        <div className="white pv32 flex flex-column justify-around mc center">
            <div className="Grid">
                <div className="u-size1of12" />
                <div className="u-size10of12">
                    <div className="pt24">
                        <GumpLogo_White />
                    </div>
                    <div className="flex flex-column flex-row-reverse-ns justify-end-ns pt42 mt10">
                        <div className="flex flex-column flex-row-ns">
                            <div className="pb24 pr32-ns pl72-ns" />
                            <div className="pb24 pr32-ns pl72-ns">
                                <p className="f16 fw6 pb12 pl0 pl40-ns mv0">
                                    Suporte
                                </p>
                                <ul className="list mv0 pl0 pl40-ns">
                                    <li className="pv4">
                                        <a
                                            href="/#"
                                            className={`link white ${styles.linkEffect}`}>
                                            Perguntas frequentes
                                        </a>
                                    </li>
                                    <li className="pv4">
                                        <a
                                            href="/#"
                                            className={`link white ${styles.linkEffect}`}>
                                            Ajuda
                                        </a>
                                    </li>
                                    <li className="pv4">
                                        <a
                                            href="mailto:gump@morpheustecnologia.com"
                                            className={`link white ${styles.linkEffect}`}>
                                            Contato
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="flex flex-column pr32-ns">
                            <p className="pb16 mv0">
                                R. Sebastião Rangel, 2695, Sala 54
                                <br /> Santa Mônica, Uberlândia - MG, 38408-250
                            </p>
                            <div className="pb16">
                                <a
                                    className={`link white ${styles.linkEffect}`}
                                    href="tel://553400000000"
                                    style={{ fontWeight: 'bold' }}>
                                    +55 34 3224-6598
                                </a>
                            </div>
                            <div className="pb16">
                                <a
                                    className={`link white ${styles.linkEffect}`}
                                    href="mailto:gump@morpheustecnologia.com">
                                    gump@morpheustecnologia.com
                                </a>
                            </div>
                            <div>
                                <a
                                    href="https://www.linkedin.com/company/morpheus-tecnologia/"
                                    className="mr10">
                                    <Linkedin />
                                </a>
                                <a
                                    href="https://www.facebook.com/morpheustecnology"
                                    className="mr10">
                                    <Facebook />
                                </a>
                                <a
                                    href="https://www.instagram.com/morpheustecnologia/"
                                    className="mr10">
                                    <Instagram />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.copyright}>
                        <div>
                            © Copyright 2019 Gump - Todos os direitos reservados
                        </div>
                        <div>
                            Criado pela{' '}
                            <a
                                className="link white"
                                href="https://morpheustecnologia.com/"
                                style={{ textDecoration: 'underline' }}>
                                Morpheus
                            </a>
                        </div>
                        <a className={styles.privacy} href="/#">
                            Politica de privacidade
                        </a>
                        <div className="u-size1of12" />
                    </div>
                </div>
            </div>
        </div>
    );
};

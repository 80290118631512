import React from 'react';

const Martins = props => (
    <svg width="64.214" height="48" {...props}>
        <defs>
            <style>{'.prefix__cls-1{fill:#adbcd8;fill-rule:evenodd}'}</style>
        </defs>
        <g id="Group_4885" transform="translate(-77.245 -236.89)">
            <path
                id="Path_1243"
                d="M133.352 503.228l1.158-2.767a9.7 9.7 0 0 0 1.287.772 4.2 4.2 0 0 0 1.158.258 1 1 0 0 0 .515-.129.486.486 0 0 0 .257-.45c0-.193-.386-.45-1.03-.579-.257-.064-.45-.064-.515-.129a3.248 3.248 0 0 1-1.737-.965 2.4 2.4 0 0 1-.579-1.673 3.152 3.152 0 0 1 1.158-2.574 4.43 4.43 0 0 1 2.96-.965 7.422 7.422 0 0 1 1.673.193 7.218 7.218 0 0 1 1.673.708l-1.094 2.574a4.166 4.166 0 0 0-1.03-.579 1.816 1.816 0 0 0-.965-.257 1.285 1.285 0 0 0-.579.129c-.193.129-.193.193-.193.322 0 .322.45.579 1.416.772a2.271 2.271 0 0 1 .386.064 2.948 2.948 0 0 1 1.609.965 2.521 2.521 0 0 1 .579 1.673 3.5 3.5 0 0 1-1.158 2.7 4.953 4.953 0 0 1-3.088 1.029 6.74 6.74 0 0 1-1.93-.322 8.049 8.049 0 0 1-1.93-.772m-10.488.836v-9.842h3.475l3.088 5.147a3.234 3.234 0 0 1-.129-.772v-4.375h3.41v9.844h-3.474l-3.024-5.148a2.423 2.423 0 0 0 .064.708v4.44zm-4.89-9.844h3.6v9.844h-3.6zm-5.919 9.844v-6.627h-1.93v-3.215h7.464v3.217h-1.995v6.627zm-10.81 0v-9.842h4.44a4.393 4.393 0 0 1 2.96.836 2.988 2.988 0 0 1 1.03 2.445 2.93 2.93 0 0 1-.515 1.737 1.832 1.832 0 0 1-1.351.772l2.574 4.054h-3.925l-1.737-3.281v3.281zm3.475-7.335v1.93h.579a1.025 1.025 0 0 0 .772-.257.8.8 0 0 0 .257-.708.967.967 0 0 0-.257-.708 1.025 1.025 0 0 0-.772-.258zm-14.349 7.335l3.539-9.844h3.925l3.41 9.844h-3.8l-.322-1.223h-2.635l-.322 1.223zm4.7-3.6h1.48l-.322-1.094a8.774 8.774 0 0 1-.257-1.287 4.351 4.351 0 0 1-.129-.9h-.064a4.343 4.343 0 0 1-.129.9 8.755 8.755 0 0 1-.257 1.287zm-7.979 3.6l-.393-3.922a3.961 3.961 0 0 1-.064-.9c-.064-.322-.064-.579-.129-.9a4.353 4.353 0 0 1-.129.9 9.145 9.145 0 0 1-.322 1.03l-1.094 3.8h-2.119l-1.094-3.8c-.129-.386-.193-.708-.322-1.03-.064-.322-.064-.579-.129-.9a4.251 4.251 0 0 0-.064.9 4.56 4.56 0 0 0-.064.9l-.451 3.925h-3.474l1.673-9.844h3.667l.708 2.445a2.787 2.787 0 0 1 .064.322 21.561 21.561 0 0 1 .579 2.509H84a10.449 10.449 0 0 1 .193-1.287c.129-.515.257-1.029.45-1.608l.708-2.381h3.669l1.544 9.844z"
                className="prefix__cls-1"
                transform="translate(0 -219.432)"
            />
            <path
                id="Path_1244"
                d="M82.512 254.456h10.617l10.552-17.566 3.282 5.4-8.944 15.056a5.511 5.511 0 0 1-4.954 2.638H82.512zm0 6.241v5.212l10.423.064a11.138 11.138 0 0 0 10.166-5.6l7.206-12.29-2.96-5.019-8.685 14.736a6.245 6.245 0 0 1-5.727 2.9zm0 5.919h10.423c4.89 0 8.622-2.123 10.81-5.855l6.949-11.9 3.153 5.534-5.212 9.2c-3.539 6.177-10.552 8.879-15.7 8.879l-10.423-.065zm62.67-12.161h-10.616l-10.424-17.565-3.346 5.4 8.944 15.056a5.545 5.545 0 0 0 5.019 2.638h10.423zm0 6.241v5.212l-10.359.064a11.137 11.137 0 0 1-10.166-5.6l-7.207-12.29 2.96-5.019 8.69 14.737a6.245 6.245 0 0 0 5.726 2.9zm0 5.919h-10.359c-4.89 0-8.622-2.123-10.81-5.855l-6.949-11.9-3.217 5.534 5.276 9.2c3.539 6.177 10.552 8.879 15.7 8.879l10.359-.065z"
                className="prefix__cls-1"
                transform="translate(-4.495)"
            />
        </g>
    </svg>
);
export default Martins;

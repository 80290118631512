import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ptBR, enUS } from './assets/translations';

i18n.use(initReactI18next).init({
    fallbackLng: 'pt',
    resources: {
        pt: ptBR,
        en: enUS,
    },
    debug: true,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;

import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Arrow } from '../../assets/icons';

export const MenuItem = ({ label, onClick, hasArrow }) => {
    const { t } = useTranslation();
    return (
        <button
            tabIndex="-1"
            className="w-100 tl bg-transparent bn pv20 gump-dark-blue flex justify-between"
            onClick={() => onClick()}>
            <p>{t(label)}</p>
            {hasArrow && <Arrow style={{ transform: 'rotate(90deg)' }} />}
        </button>
    );
};

MenuItem.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    hasArrow: PropTypes.bool,
};

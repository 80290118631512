import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { MenuItems } from './MenuItems';
// import { LanguageButton } from './LanguageButton';
import { GumpLogo_blue } from '../../assets/images';
import { Button } from '../core';
import styles from './Navbar.module.css';

export const Navbar = () => {
    const [menuStatus, setMenuStatus] = useState(false);
    const { t } = useTranslation();

    const navbarStyle = cx(
        `shadow-0 fixed w-100 bg-white justify-around z-5 overflow-hidden ${styles.navbarContent}`,
        {
            [`${styles.navbarActive}`]: menuStatus,
        }
    );
    return (
        <div className="relative w-100">
            <div className={navbarStyle}>
                <section className="mc center flex flex-column w-100">
                    <div className="Grid">
                        <div className="u-sm-size0of12 u-md-size1of12 u-lg-size1of12"></div>
                        <section className="u-size5of12">
                            <div className="flex items-center h-100 gump-blue pv20">
                                <Link to="/" className="gump-blue">
                                    <GumpLogo_blue />
                                </Link>
                            </div>
                        </section>
                        <section className="u-size5of12">
                            <div className="flex items-center justify-end h-100 pv20">
                                <div className="dn db-ns">
                                    <MenuItems
                                        currentStatus={menuStatus}
                                        statusHandler={setMenuStatus}
                                    />
                                </div>
                                {/* <LanguageButton
                                    i18nHandler={language =>
                                        i18n.changeLanguage(language)
                                    }
                                /> */}
                                <div className="dn db-ns">
                                    <Button
                                        outlined
                                        onClick={() => {
                                            window.location.href =
                                                'https://app.gump.ai/';
                                        }}
                                        className={styles.loginButton}>
                                        {t('navbar.login')}
                                    </Button>
                                </div>
                            </div>
                        </section>
                        <div className="u-sm-size0of12 u-md-size1of12 u-lg-size1of12"></div>
                    </div>
                </section>
            </div>
        </div>
    );
};

import React from 'react';
import Slider from 'react-slick';
import { MorpheusLogo, ToyGames, Algar, Martins } from '../../assets/images';
import styles from './Brand.module.css';

const settings = {
    autoplay: true,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    pauseOnHover: true,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: false,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

export const Brands = () => {
    return (
        <div className="w-100 mt16">
            <Slider {...settings}>
                <div className={`${styles.enforceFlex} justify-center`}>
                    <MorpheusLogo />
                </div>
                <div className={`${styles.enforceFlex} justify-center`}>
                    <ToyGames />
                </div>
                <div className={`${styles.enforceFlex} justify-center`}>
                    <Algar />
                </div>
                <div className={`${styles.enforceFlex} justify-center`}>
                    <Martins />
                </div>
            </Slider>
        </div>
    );
};
